import React from 'react';
import { takeEvery, put } from 'redux-saga/effects';
import loadable from 'utils/loadable';
import LoadingIndicator from 'components/LoadingIndicator';
import { dialogLoaded } from './actions';
import { defaultDialogInfo } from './reducer';

function* openDialogSaga(action) {
    const { dialogType } = action.payload;
    const { contentComponentName } = defaultDialogInfo[dialogType] || {};
  
    const DialogContentComponent = yield loadable(() =>
      import(`../../components/Dialogs/${contentComponentName}/index.js`), {
      fallback: <LoadingIndicator />,
    });

    yield put(dialogLoaded(dialogType, DialogContentComponent));
  }
  

function* dialogSaga() {
    yield takeEvery('OPEN_DIALOG', openDialogSaga);
}
  
export default dialogSaga;
