import * as types from './types';
import { logout } from '../../containers/App/actions';

const userMiddleware = (store) => (next) => (action) => {
  const { type } = action;

  if (type === types.UPDATE_SETTING_SUCCESS) {
    const { name } = action?.settings?.user;
  
    if (name === 'allowDataCollection') {
      window.location.reload();
    }
  }

  if (type === types.DELETE_ACCOUNT_SUCCESS) {
    store.dispatch(logout());
  }

  return next(action);
};

export default userMiddleware;
