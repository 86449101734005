import * as types from './types';

export function openSidebar(name, toggle, metadata = null) {
    return {
      type: types.OPEN_SIDEBAR,
      payload: {
        name,
        metadata,
        toggle,
      },
    };
}

export function closeSidebar(name) {
    return {
      type: types.CLOSE_SIDEBAR,
      payload: {
        name,
      },
    };
}
