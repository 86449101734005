import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import history from 'utils/history';
import globalReducer from 'containers/App/reducer';
import languageProviderReducer from 'containers/LanguageProvider/reducer';

import conversationReducer from './redux/conversations/reducer';
import dialogReducer from './redux/dialogs/reducer';
import loaderReducer from './redux/loaders/reducer';
import toastReducer from './redux/toasts/reducer';
import userReducer from './redux/user/reducer';
import breadcrumbReducer from './redux/breadcrumps/reducer';
import agentsReducer from './redux/agents/reducer';
import sidebarReducer from './redux/sidebar/reducer';

export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    global: globalReducer,
    language: languageProviderReducer,
    router: connectRouter(history),
    conversations: conversationReducer,
    dialogs: dialogReducer,
    loaders: loaderReducer,
    toasts: toastReducer,
    user: userReducer,
    breadcrumb: breadcrumbReducer,
    agents: agentsReducer,
    sidebar: sidebarReducer,
    ...injectedReducers,
  });

  return rootReducer;
}
