export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const LOGIN_TOKEN = 'LOGIN_TOKEN';
export const LOGIN_TOKEN_SUCCESS = 'LOGIN_TOKEN_SUCCESS';
export const LOGIN_TOKEN_FAIL = 'LOGIN_TOKEN_FAIL';

export const REGISTER = 'REGISTER';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';

export const GET_USER = 'GET_USER';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAIL = 'GET_USER_FAIL';

export const SEND_MAGIC_LINK = 'SEND_MAGIC_LINK';
export const SEND_MAGIC_LINK_SUCCESS = 'SEND_MAGIC_LINK_SUCCESS';
export const SEND_MAGIC_LINK_FAIL = 'SEND_MAGIC_LINK_FAIL';

export const LOGIN_MAGIC_LINK = 'LOGIN_MAGIC_LINK';
export const LOGIN_MAGIC_LINK_SUCCESS = 'LOGIN_MAGIC_LINK_SUCCESS';
export const LOGIN_MAGIC_LINK_FAIL = 'LOGIN_MAGIC_LINK_FAIL';

export const EMAIL_VALIDATION = 'EMAIL_VALIDATION';
export const EMAIL_VALIDATION_SUCCESS = 'EMAIL_VALIDATION_SUCCESS';
export const EMAIL_VALIDATION_FAIL = 'EMAIL_VALIDATION_FAIL';

export const NEW_PASSWORD = 'NEW_PASSWORD';
export const NEW_PASSWORD_SUCCESS = 'NEW_PASSWORD_SUCCESS';
export const NEW_PASSWORD_FAIL = 'NEW_PASSWORD_FAIL';

export const RESET_SEARCH = 'RESET_SEARCH';

export const SEARCH_JOBS = 'SEARCH_JOBS';
export const SEARCH_JOBS_SUCCESS = 'SEARCH_JOBS_SUCCESS';
export const SEARCH_JOBS_FAIL = 'SEARCH_JOBS_FAIL';

export const SEARCH_JOBS_HISTORY = 'SEARCH_JOBS_HISTORY';
export const SEARCH_JOBS_HISTORY_SUCCESS = 'SEARCH_JOBS_HISTORY_SUCCESS';
export const SEARCH_JOBS_HISTORY_FAIL = 'SEARCH_JOBS_HISTORY_FAIL';

export const FOCUS_PROMPT = 'FOCUS_PROMPT';

export const SEARCH_MESSAGE = 'SEARCH_MESSAGE';

export const SET_CAROUSEL_CURRENT_INDEX = 'SET_CAROUSEL_CURRENT_INDEX';


