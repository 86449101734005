import * as types from './types';

const initialState = {
    settings: null,
  };
  
  const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_SETTINGS_SUCCESS:
            return {
                ...state,
                settings: action.payload,
            };
    
        case types.UPDATE_SETTING_SUCCESS:
            const updatedSettings = {
              ...state.settings,
              [action.settings.user.name]: action.payload.value,
            };
      
            return {
              ...state,
              settings: updatedSettings,
            };
      default:
        return state;
    }
  };
  
  export default userReducer;
  