import * as types from './types';

const initialState = {
    conversations: [],
};

const agentsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_CONVERSATIONS_SUCCESS: {
            console.log('conversations', action.payload.conversations[0])
            return {
                ...state,
                conversations: action.payload.conversations,
            };
        }

        case types.RESET_CONVERSATIONS: {
            return {
                ...state,
                conversations: [],
            };
        }

        default:
            return state;
    }
};

export default agentsReducer;
