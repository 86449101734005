import * as types from './types';

export const startLoading = (loader) => {
    return {
      type: types.START_LOADING,
      loader,
    };
  };
  
  export const stopLoading = (loader) => {
    return {
      type: types.STOP_LOADING,
      loader,
    };
  };
  