import styled from 'styled-components';

// import NormalImg from 'components/Img';

const Img = styled.img`
  width: 70px;
  margin: 0 auto;
  display: block;
  margin-right: 0px;
`;

export default Img;
