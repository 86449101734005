export const UPDATE_SETTING = 'UPDATE_SETTING';
export const UPDATE_SETTING_SUCCESS = 'UPDATE_SETTING_SUCCESS';
export const UPDATE_SETTING_FAIL = 'UPDATE_SETTING_FAIL';

export const GET_SETTINGS = 'GET_SETTINGS';
export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS';
export const GET_SETTINGS_FAIL = 'GET_SETTINGS_FAIL';

export const UNLINK_GOOGLE = 'UNLINK_GOOGLE';
export const UNLINK_GOOGLE_SUCCESS = 'UNLINK_GOOGLE_SUCCESS';
export const UNLINK_GOOGLE_FAIL = 'UNLINK_GOOGLE_FAIL';

export const UNLINK_LINKEDIN = 'UNLINK_LINKEDIN';
export const UNLINK_LINKEDIN_SUCCESS = 'UNLINK_LINKEDIN_SUCCESS';
export const UNLINK_LINKEDIN_FAIL = 'UNLINK_LINKEDIN_FAIL';

export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS';
export const DELETE_ACCOUNT_FAIL = 'DELETE_ACCOUNT_FAIL';

export const NEW_PASSWORD = 'NEW_PASSWORD';
export const NEW_PASSWORD_SUCCESS = 'NEW_PASSWORD_SUCCESS';
export const NEW_PASSWORD_FAIL = 'NEW_PASSWORD_FAIL';
