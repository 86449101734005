import React, { memo } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Chip, ScrollFade } from '@ozlydev/ozly-sand-ui';
import { makeSelectCurrentUser } from '../../containers/App/selectors';
import NavBar from './NavBar';
import HeaderLink from './HeaderLink';
import Banner from './logo-ozly-grey.svg';
import Img from './Img';

function Header({ user }) {
  return (
    <div
      style={{ zIndex: 10, position: 'fixed', top: 0, left: 0, right: 0, pointerEvents: 'none'}}
    >
      <ScrollFade>
        <div>
          <NavBar>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <HeaderLink to={'/' + window.location.pathname.split('/')?.[1]} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', pointerEvents: 'initial' }}>
                <Img
                  src={Banner}
                  alt="Ozly - Logo" />
                <Chip>BÊTA</Chip>
              </HeaderLink>
            </div>
          </NavBar>
        </div>
      </ScrollFade>
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  user: makeSelectCurrentUser(),
});

function mapDispatchToProps(dispatch) {
  return {
    // onLogin: (data) => dispatch(login(data)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  memo,
)(Header);
