import { put, take, call, takeLatest, select } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';

import { store } from '../../app';
const RECONNECTION_DELAY = 5000;  // 5 seconds, but you can adjust this value

let isConnecting = false;
let sockets = {};

export function* onTest() {
    isConnecting = true;
    const socket = yield call(connect);

    if (socket && socket.readyState !== WebSocket.CLOSED) {
        const socketChannel = yield call(createSocketChannel, socket);
        
        while (true) {
            let payload = yield take(socketChannel);
            payload = JSON.parse(payload);

            yield put({ type: 'SOCKET_MESSAGE_RECEIVED', payload });

            if (payload?.reduxAction) {
                yield put(payload.payload);
            }
        }
    } else {
        console.log('Failed to establish connection.');
    }

    isConnecting = false;
}
  

function* socketLangServeSaga() {
    yield takeLatest('CONNECT_SOCKET_LANGSERVE', onTest);
}

function* connect(name = "langserv", url = process.env.LANG_SERVE_SOCKET_URL, cb) {
    const user_id = yield select(state => state.global.user?._id);
    url = `${url}/${user_id}`

    const socket = new WebSocket(url);

    socket.onopen = () => {
        console.log('WebSocket connected2!');
    };

    socket.onclose = (event) => {
        if (event.wasClean) {
            console.log(`Closed cleanly, code=${event.code}, reason=${event.reason}`);
        } else {
            console.log('DEAD CONNECTION');
        }

        store.dispatch(connectSocket());
    };

    socket.onerror = (error) => {
        console.log(`WebSocket Error: ${error.message}`);
    };

    sockets[name] = socket;

    return sockets[name];
}


function createSocketChannel(socket) {
    return eventChannel((emit) => {
        socket.onmessage = (event) => {
            emit(event.data);
        };

        const unsubscribe = () => {
            socket.onmessage = null;
        };

        return unsubscribe;
    });
}

export const connectSocket = () => {
    return {
      type: 'CONNECT_SOCKET_LANGSERVE'
    };
  };

  export default socketLangServeSaga;


