export const GET_AGENTS_CHARACTERS = 'GET_AGENTS_CHARACTERS';
export const GET_AGENTS_CHARACTERS_SUCCESS = 'GET_AGENTS_CHARACTERS_SUCCESS';
export const GET_AGENTS_CHARACTERS_FAIL = 'GET_AGENTS_CHARACTERS_FAIL';

export const GET_AGENTS_CHARACTER = 'GET_AGENTS_CHARACTER';
export const GET_AGENTS_CHARACTER_SUCCESS = 'GET_AGENTS_CHARACTER_SUCCESS';
export const GET_AGENTS_CHARACTER_FAIL = 'GET_AGENTS_CHARACTER_FAIL';

export const ORDER_AGENTS_CHARACTERS = 'ORDER_AGENTS_CHARACTERS';
export const ORDER_AGENTS_CHARACTERS_SUCCESS = 'ORDER_AGENTS_CHARACTERS_SUCCESS';
export const ORDER_AGENTS_CHARACTERS_FAIL = 'ORDER_AGENTS_CHARACTERS_FAIL';

export const UPDATE_AGENTS_CHARACTER = 'UPDATE_AGENTS_CHARACTER';
export const UPDATE_AGENTS_CHARACTER_SUCCESS = 'UPDATE_AGENTS_CHARACTER_SUCCESS';
export const UPDATE_AGENTS_CHARACTER_FAIL = 'UPDATE_AGENTS_CHARACTER_FAIL';

export const ADD_AGENTS_CHARACTER = 'ADD_AGENTS_CHARACTER';
export const ADD_AGENTS_CHARACTER_SUCCESS = 'ADD_AGENTS_CHARACTER_SUCCESS';
export const ADD_AGENTS_CHARACTER_FAIL = 'ADD_AGENTS_CHARACTER_FAIL';

export const REMOVE_AGENTS_CHARACTER = 'REMOVE_AGENTS_CHARACTER';
export const REMOVE_AGENTS_CHARACTER_SUCCESS = 'REMOVE_AGENTS_CHARACTER_SUCCESS';
export const REMOVE_AGENTS_CHARACTER_FAIL = 'REMOVE_AGENTS_CHARACTER_FAIL';

export const UPDATE_AGENTS = 'UPDATE_AGENTS';
export const UPDATE_AGENTS_SUCCESS = 'UPDATE_AGENTS_SUCCESS';
export const UPDATE_AGENTS_FAIL = 'UPDATE_AGENTS_FAIL';

export const UPDATE_RAGS = 'UPDATE_RAGS';
export const UPDATE_RAGS_SUCCESS = 'UPDATE_RAGS_SUCCESS';
export const UPDATE_RAGS_FAIL = 'UPDATE_RAGS_FAIL';

export const ADD_AGENTS_GROUPS_CHARACTER = 'ADD_AGENTS_GROUPS_CHARACTER';
export const ADD_AGENTS_GROUPS_CHARACTER_SUCCESS = 'ADD_AGENTS_GROUPS_CHARACTER_SUCCESS';
export const ADD_AGENTS_GROUPS_CHARACTER_FAIL = 'ADD_AGENTS_GROUPS_CHARACTER_FAIL';

export const REMOVE_AGENTS_GROUPS_CHARACTER = 'REMOVE_AGENTS_GROUPS_CHARACTER';
export const REMOVE_AGENTS_GROUPS_CHARACTER_SUCCESS = 'REMOVE_AGENTS_GROUPS_CHARACTER_SUCCESS';
export const REMOVE_AGENTS_GROUPS_CHARACTER_FAIL = 'REMOVE_AGENTS_GROUPS_CHARACTER_FAIL';

export const ADD_AGENTS_TO_GROUPS_CHARACTER = 'ADD_AGENTS_TO_GROUPS_CHARACTER';
export const ADD_AGENTS_TO_GROUPS_CHARACTER_SUCCESS = 'ADD_AGENTS_TO_GROUPS_CHARACTER_SUCCESS';
export const ADD_AGENTS_TO_GROUPS_CHARACTER_FAIL = 'ADD_AGENTS_TO_GROUPS_CHARACTER_FAIL';

export const UNSET_CURRENT_AGENT = 'UNSET_CURRENT_AGENT';

export const GET_AGENT_CHAIN = 'GET_AGENT_CHAIN';
export const GET_AGENT_CHAIN_SUCCESS = 'GET_AGENT_CHAIN_SUCCESS';
export const GET_AGENT_CHAIN_FAIL = 'GET_AGENT_CHAIN_FAIL';