import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    line-height: 1.5;
  }

  body {
    font-family: 'Satoshi-Regular', Helvetica, Arial, sans-serif;
  }

  body.fontLoaded {
    font-family: 'Satoshi-Regular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  #app {
    min-height: 100%;
    min-width: 100%;
    overflow-x: hidden;
  }

  p,
  label {
    font-family: Satoshi-Regular, Times, 'Times New Roman', serif;
    line-height: 1.5em;
  }

  .slick-track {
    display: flex !important;
  }
`;

export default GlobalStyle;
