import * as types from './types';

let nextToastId = 0;

export const showToast = (message, id, params) => ({
  type: types.SHOW_TOAST,
  payload: { message, id, params },
});

export const setContainerHover = (hovered) => ({
  type: types.SET_CONTAINER_HOVER,
  payload: { hovered },
});

export const removeToast = (toastId) => ({
  type: types.REMOVE_TOAST,
  payload: { toastId },
});

export const showAndRemove = (message, params) => ({
  type: types.SHOW_AND_REMOVE,
  payload: { message, id: nextToastId++, params },
});

