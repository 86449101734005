import * as types from './types';

export function orderCharacter(idCharacter, from, to) {
  console.log('orderCharacter', idCharacter, from, to)
  return {
    type: [types.ORDER_AGENTS_CHARACTERS, types.ORDER_AGENTS_CHARACTERS_SUCCESS, types.ORDER_AGENTS_CHARACTERS_FAIL],
    baseUrl: `/api/agents/admin/${idCharacter}/order`,
    requestType: 'put',
    body: {
      from,
      to
    },
    loader: 'getAgentsCharacters',
  };
}

export function getAgentsCharacters() {
  return {
    type: [types.GET_AGENTS_CHARACTERS, types.GET_AGENTS_CHARACTERS_SUCCESS, types.GET_AGENTS_CHARACTERS_FAIL],
    baseUrl: `/api/agents/`,
    requestType: 'get',
    loader: 'getAgentsCharacters',
  };
}

export function getAgentsCharacter(name) {
  return {
    type: [types.GET_AGENTS_CHARACTER, types.GET_AGENTS_CHARACTER_SUCCESS, types.GET_AGENTS_CHARACTER_FAIL],
    baseUrl: `/api/agents/admin/${name}`,
    requestType: 'get',
    loader: 'getAgentsCharacter',
  };
}

export function unsetCurrentAgent() {
  console.log('unseting ?')
  return {
    type: types.UNSET_CURRENT_AGENT
  };
}

export function addCharacter(data) {
  return {
    type: [types.ADD_AGENTS_CHARACTER, types.ADD_AGENTS_CHARACTER_SUCCESS, types.ADD_AGENTS_CHARACTER_FAIL],
    baseUrl: `/api/agents/admin/character`,
    body: data,
    requestType: 'post',
    loader: 'addAgentsCharacter',
    filter: {
      name: data.name
    }
  };
}

export function editCharacterGroup(data) {
  return {
    type: [types.ADD_AGENTS_GROUPS_CHARACTER, types.ADD_AGENTS_GROUPS_CHARACTER_SUCCESS, types.ADD_AGENTS_GROUPS_CHARACTER_FAIL],
    baseUrl: `/api/agents/admin/character/group`,
    body: data,
    requestType: 'post',
    loader: 'addAgentsGroupCharacter',
    filter: {
      name: data.name
    }
  };
}

export function removeCharacterGroup(idGroup) {
  console.log('làlàlà', {idGroup})
  return {
    type: [types.REMOVE_AGENTS_GROUPS_CHARACTER, types.REMOVE_AGENTS_GROUPS_CHARACTER_SUCCESS, types.REMOVE_AGENTS_GROUPS_CHARACTER_FAIL],
    baseUrl: `/api/agents/admin/character/group/${idGroup}`,
    requestType: 'delete',
    loader: 'removeAgentsGroupCharacter'
  };
}

export function addCharacterToGroup(idCharacter, idGroup) {
  return {
    type: [types.ADD_AGENTS_TO_GROUPS_CHARACTER, types.ADD_AGENTS_TO_GROUPS_CHARACTER_SUCCESS, types.ADD_AGENTS_TO_GROUPS_CHARACTER_FAIL],
    baseUrl: `/api/agents/admin/character/${idCharacter}/group/${idGroup}`,
    requestType: 'put',
    loader: 'addAgentsToGroupCharacter',
  };
}

export function removeCharacter(_id) {
  return {
    type: [types.REMOVE_AGENTS_CHARACTER, types.REMOVE_AGENTS_CHARACTER_SUCCESS, types.REMOVE_AGENTS_CHARACTER_FAIL],
    baseUrl: `/api/agents/admin/${_id}/character`,
    requestType: 'delete',
    loader: 'deleteAgentsCharacter'
  };
}

export function updateCharacter(name, data) {
  return {
    type: [types.UPDATE_AGENTS_CHARACTER, types.UPDATE_AGENTS_CHARACTER_SUCCESS, types.UPDATE_AGENTS_CHARACTER_FAIL],
    baseUrl: `/api/agents/admin/${name}/character`,
    body: data,
    requestType: 'put',
    loader: 'updateAgentsCharacter',
    filter: {
      name
    }
  };
}

export function updateAgent(name, data) {
  return {
    type: [types.UPDATE_AGENTS, types.UPDATE_AGENTS_SUCCESS, types.UPDATE_AGENTS_FAIL],
    baseUrl: `/api/agents/admin/${name}/agent`,
    body: data,
    requestType: 'put',
    loader: 'updateAgent',
    filter: {
      name
    }
  };
};

export function updateRag(name, _id, idAgentCharacter, idAgentPrompt, content) {
  return {
    type: [types.UPDATE_RAGS, types.UPDATE_RAGS_SUCCESS, types.UPDATE_RAGS_FAIL],
    baseUrl: `/api/agents/admin/${idAgentCharacter}/rag/`,
    body: {
        _id,
        idAgentPrompt,
        content
    },
    requestType: 'put',
    loader: 'updateRag',
    filter: {
      name
    }
  };
}

export function deleteRag(name, _id, idAgentCharacter) {
  return {
    type: [types.UPDATE_RAGS, types.UPDATE_RAGS_SUCCESS, types.UPDATE_RAGS_FAIL],
    baseUrl: `/api/agents/admin/${idAgentCharacter}/rag/${_id}`,
    requestType: 'delete',
    loader: 'updateRag',
    filter: {
      name
    }
  };
}
