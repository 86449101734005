/**
 * Create the store with dynamic reducers
 */

import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware, { runSaga } from 'redux-saga';
import createReducer from './reducers';
import { useInjectSaga } from './utils/injectSaga';

import socketSaga from './redux/socket/saga';
import socketLangServeSaga from './redux/socketLangServe/saga';
import appSaga from './containers/App/saga';
import breadcrumbSaga from './redux/breadcrumps/sagas';

import conversationMiddleware from './redux/conversations/middleware';
import dialogMiddleware from './redux/dialogs/middleware';
import loaderMiddleware from './redux/loaders/middleware';
import toastMiddleware from './redux/toasts/middleware';
import redirectMiddleware from './redux/redirect/middleware';
import userMiddleware from './redux/user/middleware';
import agentsMiddleware from './redux/agents/middleware';
import sidebarMiddleware from './redux/sidebar/middleware';

export default function configureStore(initialState = {}, history) {
  let composeEnhancers = compose;
  const reduxSagaMonitorOptions = {};

  // If Redux Dev Tools and Saga Dev Tools Extensions are installed, enable them
  /* istanbul ignore next */
  if (process.env.NODE_ENV !== 'production' && typeof window === 'object') {
    /* eslint-disable no-underscore-dangle */
    if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
      composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});

    // NOTE: Uncomment the code below to restore support for Redux Saga
    // Dev Tools once it supports redux-saga version 1.x.x
    // if (window.__SAGA_MONITOR_EXTENSION__)
    //   reduxSagaMonitorOptions = {
    //     sagaMonitor: window.__SAGA_MONITOR_EXTENSION__,
    //   };
    /* eslint-enable */
  }

  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);

  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [
    sagaMiddleware,
    routerMiddleware(history),
    dialogMiddleware,
    conversationMiddleware,
    loaderMiddleware,
    toastMiddleware,
    redirectMiddleware,
    userMiddleware,
    agentsMiddleware,
    sidebarMiddleware,
  ];

  const enhancers = [applyMiddleware(...middlewares)];

  const store = createStore(
    createReducer(),
    initialState,
    composeEnhancers(...enhancers),
  );

  // Extensions
  store.runSaga = sagaMiddleware.run;
  store.injectedReducers = { }; // Reducer registry
  store.injectedSagas = {}; // Saga registry

  store.runSaga(appSaga, 'auth');
  store.runSaga(socketSaga);
  store.runSaga(socketLangServeSaga);
  store.runSaga(breadcrumbSaga);

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(createReducer(store.injectedReducers));
    });
  }

  return store;
}
