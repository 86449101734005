import { startLoading, stopLoading } from './actions';

const loaderMiddleware = (store) => (next) => (action) => {
  const { loader } = action;
  const { type } = action;

  if (!loader || Array.isArray(type) || ['START_LOADING', 'STOP_LOADING'].includes(type)) {
    return next(action);
  }

  if ((type?.endsWith('_SUCCESS') || type?.endsWith('_FAIL'))) {
        store.dispatch(stopLoading(loader));
        return next(action);
  }

      store.dispatch(startLoading(loader));

  return next(action);
};

export default loaderMiddleware;
