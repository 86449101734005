import { push } from 'connected-react-router';

const redirectMiddleware = (store) => (next) => (action) => {
  const { redirect } = action;
  const { type } = action;

  if (!redirect || Array.isArray(type)) {
    return next(action);
  }

  if (redirect.success && type?.endsWith('_SUCCESS')) {
      store.dispatch(push(redirect.success));
  }

  if (redirect.fail && type?.endsWith('_FAIL')) {
      store.dispatch(push(redirect.fail));
  }

  return next(action);
};

export default redirectMiddleware;
