import * as types from './types';

const initialState = {
    loaders: {},
  };
  
  const loaderReducer = (state = initialState, action) => {
    switch (action.type) {
      case types.START_LOADING:
        return {
          ...state,
            [action.loader]: "true",
        };
      case types.STOP_LOADING:
        return {
          ...state,
            [action.loader]: undefined,
        };
      default:
        return state;
    }
  };
  
  export default loaderReducer;
  