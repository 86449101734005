import { takeEvery, select, put } from 'redux-saga/effects';
import { LOCATION_CHANGE, push } from 'connected-react-router';
import { setBreadcrumb } from './actions';
import { resetSearch as resetSearchAction } from '../../containers/App/actions';

function* handleBreadcrumbClick({ to, resetSearch }) {
  if (resetSearch) {
    yield put(resetSearchAction());
  }

   yield put(push(to));
}

function* handleRouteChange() {
  const location = yield select(state => state.router.location);

  let breadcrumbItems = [];

  switch (location.pathname) {
    case '/search':
      breadcrumbItems = [
        { label: 'Rechercher', to: '/', resetSearch: true  },
        { label: 'Offres proposées' }
      ];
      break;
  }

  if (location.pathname.includes('/similar')) {
    breadcrumbItems = [
      { label: 'Rechercher', to: '/', resetSearch: true },
      { label: 'Offres proposées', to: '/' },
      { label: 'Offre d\'emploi' },
      { label: 'Similaires' } 
    ];
  } else if (location.pathname.includes('/job/')) {
    breadcrumbItems = [
      { label: 'Rechercher', to: '/', resetSearch: true },
      { label: 'Offres proposées', to: '/' },
      { label: 'Offre d\'emploi' } // TODO: Add job title
    ];
  }

  yield put(setBreadcrumb(breadcrumbItems));
}

function* breadcrumbSaga() {
  yield takeEvery(LOCATION_CHANGE, handleRouteChange);
  yield takeEvery('BREADCRUMB_CLICKED', handleBreadcrumbClick);
}

export default breadcrumbSaga;
