import * as types from './types';


export const defaultDialogInfo = {
    default: {
      title: 'Mot de passe oublié ?',
      subtitle: 'Indiquez votre adresse mail, nous vous enverrons un e-mail avec un lien de réinitialisation.',
      contentComponentName: 'Templates/Default',
    },
    lostPassword: {
        title: 'Mot de passe oublié ?',
        subtitle: 'Indiquez votre adresse mail, nous vous enverrons un e-mail avec un lien de réinitialisation.',
        contentComponentName: 'Auth/LostPassword',
    },
    login: {
        title: 'Se connecter',
        subtitle: '',
        contentComponentName: 'Auth/Login',
        widthSize: 'medium',
    },
    newPassword: {
        title: 'Changer son mot de passe',
        subtitle: '',
        contentComponentName: 'Auth/NewPassword',
        widthSize: 'medium',
        widthInherit: true,
    },
    register: {
        title: "S'inscrire",
        subtitle: 'Créez votre compte pour retrouver à tout moment votre historique de recherche et vos offres préférées..',
        contentComponentName: 'Auth/Register',
    },
    userAlertsCreate: {
        iconName: 'Ring',
        title: 'Créez une alerte',
        subtitle: "Indiquez votre e-mail et sélectionnez la fréquence de l'alerte. Vous pourrez la modifier à tout moment.",
        contentComponentName: 'User/Alerts/Create'
    },
    userAlertsKeepInTouch: {
      iconName: 'Ring',
      title: 'On se retrouve bientôt ?',
      subtitle: "Indiquez votre e-mail",
      contentComponentName: 'User/Alerts/KeepInTouch'
  },
    magicLinkSent: {
      title: 'Lien magic envoyé',
      subtitle: 'Indiquez votre adresse mail, nous vous enverrons un e-mail avec un lien de réinitialisation.',
      contentComponentName: 'Auth/MagicLinkSent',
      iconName: 'Mail',
  },
  jobShare: {
    contentComponentName: 'Job/Share',
  },
  platformShare: {
    contentComponentName: 'Platform/Share',
  },
  };
  
const initialState = {
    opened: [],
    openDialogs: [],
    loadedDialogComponents: {},
    activeDialogs: {},
  };
  
  const dialogReducer = (state = initialState, action) => {
    switch (action.type) {
      case types.ADD_DIALOG:
        return {
          ...state,
          opened: [...state.opened, action.payload],
        };
  
      case types.REMOVE_DIALOG:
        return {
          ...state,
          opened: [...state.opened.slice(0, -1)],
        };
  
      case types.OPEN_DIALOG:
        const { dialogType, params } = action.payload;

        return {
          ...state,
          openDialogs: [dialogType, ...state.openDialogs],
          activeDialogs: {
            ...state.activeDialogs,
            [dialogType]: {
                ...defaultDialogInfo[dialogType],
                ...params,
            },
          },
        };
  
     
    case types.CLOSE_DIALOG:
        const closedDialogType = action.payload.dialogType;
        const updatedOpenDialogs = state.openDialogs.filter(
          (type) => type !== closedDialogType
        );
        const updatedActiveDialogs = { ...state.activeDialogs };
        delete updatedActiveDialogs[closedDialogType];
  
        return {
          ...state,
          openDialogs: updatedOpenDialogs,
          activeDialogs: updatedActiveDialogs,
        };
  
      case types.DIALOG_LOADED:
        const loadedDialogType = action.payload.dialogType;
        const loadedDialogComponent = action.payload.DialogComponent;
  
        return {
          ...state,
          loadedDialogComponents: {
            ...state.loadedDialogComponents,
            [loadedDialogType]: loadedDialogComponent,
          },
      };

      case types.RESET_DIALOG_STATE:
        return {
            ...state,
            openDialogs: [],
            activeDialogs: {},
        };
      default:
        return state;
    }
  };
  
  export default dialogReducer;
  