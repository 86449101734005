import React from 'react';
import * as types from './types'
import { put, delay, select, takeEvery } from 'redux-saga/effects';
import { showToast, removeToast } from './actions';

function* showAndRemoveToast(action) {
  const { message, id, params = { delay: 6000} } = action.payload;

  yield put(showToast(message, id, params));

  while (true) {
    yield delay(params?.delay || 6000); 
    
    const containerHovered = yield select(state => state.toasts.containerHovered);
    if (!containerHovered) {
      break;
    }
  }

  yield put(removeToast(id));
}

function* toastSaga() {
  yield takeEvery(types.SHOW_AND_REMOVE, showAndRemoveToast);
}

export default toastSaga;

