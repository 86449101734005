import styled from 'styled-components';

export default styled.div`
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;
  min-height: 80px;

  > a {
    color: #494A53;
  }

  // @media (max-width: 768px) {
  //   display: none;
  // }
`;
