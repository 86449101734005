import * as types from './types';

const initialState = {
  list: [],
  containerHovered: false,
};

const toastsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_TOAST:
      return {
        ...state,
        list: [...state.list, { ...action.payload, hovered: false }],
      };

    case types.SET_CONTAINER_HOVER:
      return {
        ...state,
        containerHovered: action.payload.hovered,
      };

    case types.REMOVE_TOAST:
      return {
        ...state,
        list: state.list.filter(toast => toast.id !== action.payload.toastId),
      };

    default:
      return state;
  }
};

export default toastsReducer;
