/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import styled, { css } from 'styled-components';
import { Switch, Route } from 'react-router-dom';
import { useInjectSaga } from 'utils/injectSaga';

import toastSaga from '../../redux/toasts/saga';
import dialogSaga from '../../redux/dialogs/saga';

import LoginPage from 'containers/LoginPage/Loadable';
import AgentPage from 'containers/AgentPage/Loadable';
import AgentsPage from 'containers/AgentsPage/Loadable';
import ConversationsPage from 'containers/ConversationsPage/Loadable';
import LogoutPage from 'containers/LogoutPage/Loadable';

import Header from 'components/Header';
import Footer from '../../components/Footer';
import ChatMenu from '../../components/Chat/ChatMenu';

import GlobalStyle from '../../global-styles';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { ThemeProvider, darkTheme, SinoLines } from '@ozlydev/ozly-sand-ui';
import DialogSimple from '../../components/Dialog/Loadable';
import Toasts from '../../components/Toasts/Loadable';
import '@ozlydev/ozly-sand-ui/styles.css';

const AppWrapper = styled.div`
  max-width: calc(768px + 16px * 2);
  margin: 0 auto;
  display: flex;
  min-height: 100%;
  padding: 0 16px;
  flex-direction: column;

  ${(props) => props.$fullWidth
    && css`
      max-width: 100%;
      padding: 0 16px;
  `}
`;

export default function App() {
  useInjectSaga({ key: 'toasts', saga: toastSaga });
  useInjectSaga({ key: 'dialogs', saga: dialogSaga });
  const agents = useSelector((state) => state.agents.characters);
  const agent = agents.find((a) => a.pathNames?.includes(window.location.pathname));

  return (
    <ThemeProvider theme={darkTheme}>
      <AppWrapper $fullWidth={agent != null}>
        <div style={{ 
          position: 'fixed',
          pointerEvents: 'none',
          left: 0,
          right: 0,
          top: 250,
          }}
        >
          {/* <SinoLines /> */}
        </div>
        <Helmet titleTemplate="%s - Ozly" defaultTitle="Ozly" />
        <Header />
        {agent == null && (
          <ChatMenu />
        )}
        <Switch>
          <Route path="/agents/:agent" component={AgentPage} />
          <Route path={["/agents"]} component={AgentsPage} />
          <Route path={["/conversations"]} component={ConversationsPage} />

          <Route path={["/", "/login"]} component={LoginPage} />
          <Route path="/logout" component={LogoutPage} />
        </Switch>
        {/* <Footer /> */}
        <GlobalStyle />
        <Toasts />
        {/* <Dialogs /> */}
        <DialogSimple />
        <Footer />     
      </AppWrapper>
    </ThemeProvider>
  );
}
