import { NavLink } from 'react-router-dom';
import styled from 'styled-components';



// brown 10
export default styled(NavLink)`
  &.active {
      font-weight: bold;
      
      > li {
        background-color: #D79F8110 !important;
      }
    }
    
    text-decoration: none;
    color: initial;
`;