import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { Button,  FadeInWrapper } from '@ozlydev/ozly-sand-ui';

const Container = styled.div`
    z-index: 10;
    position: fixed;
    padding-top: 128px;
    padding-bottom: 64px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    display: flex;
    justify-content: center;
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, #fff2e8 50%);
    pointer-events: none;
    // align-items: center;
`;

function Footer({ user }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const currentSearch = useSelector(state => state.global.currentSearch);

  if (!['/alerts', '/likes', '/history'].includes(location.pathname)) {
    return null;
  }

  return (
    <FadeInWrapper>
    <Container>
      <div style={{ display: 'flex', alignItems: 'center', pointerEvents: 'all' }}>
        { currentSearch && <Button
            mr="16"
            onClick={() => {
                dispatch(push('/'))
            }}
            variant="ghost"
            leftIcon="Brain">
            Recherche en cours
          </Button>}
        <Button
            onClick={() => {
                dispatch(push('/'))
            }}
            active
            variant="ghost"
            leftIcon="Sunlight">
            Lancer une recherche
          </Button>
      </div>
    </Container>
    </FadeInWrapper>
    );
}

export default memo(Footer);