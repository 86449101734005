import * as types from './types';

export function getConversations(from, to) {
  return {
    type: [types.GET_CONVERSATIONS, types.GET_CONVERSATIONS_SUCCESS, types.GET_CONVERSATIONS_FAIL],
    baseUrl: `/api/agents/admin/conversations`,
    requestType: 'get',
    body: {
      from,
      to
    },
    loader: 'getConversations',
  };
}

export function resetConversations() {
  return {
    type: types.RESET_CONVERSATIONS,
  };
}
