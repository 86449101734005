import { closeSidebar } from "./actions";

const sidebarMiddleware = (store) => (next) => (action) => {
    const { type } = action;

    if (type === '@@router/LOCATION_CHANGE') {
        store.dispatch(closeSidebar('main'));
    }

  return next(action);
};

export default sidebarMiddleware;
